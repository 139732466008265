<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    color="#7A1878"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="300"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item-content>
        <v-img
          id="logoCompany"
          src="@/assets/travikr-logo-color.png"
          max-height="100"
          contain
          @click="iconClick"
        />
      </v-list-item-content>
      <v-list-item-content style="text-align: center">
        <span>
          {{ user.c_fname }} {{ user.c_lname }}
        </span>

        <span class="mt-2">
          {{ user.c_division }} - {{ user.c_position }}
        </span>
      </v-list-item-content>
    </v-list>

    <!-- <v-divider class="mb-2" /> -->

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />

        <!-- <v-divider
          v-if="item.separator"
          :key="`divider-${i}`"
          class="mb-2"
        /> -->
        <div
          v-if="item.separator"
          :key="`divider-${i}`"
          class="mb-2"
          style="
            border-bottom: 1px solid #691570;
          "
        />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapState } from 'vuex'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'Beranda',
          to: '/',
          separator: true,
        },
      ],

      user: {
        c_fname: '',
        c_lname: '',
        c_division: '',
        c_position: '',
      },
    }),

    computed: {
      ...mapState({
        barImage: state => state.setting.barImage,
        barColor: state => state.setting.barColor,
        userinfo: state => state.user.userinfo,
      }),
      drawer: {
        get () {
          return this.$store.state.setting.drawer
        },
        set (val) {
          this.$store.commit('setting/SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    created () {
      this.initialize()
    },

    mounted () {
      this.user = JSON.parse(this.userinfo)
    },

    methods: {
      initialize () {
        const userMenus = localStorage.getItem('userMenus').split(',')

        if (userMenus.includes('CUSTOMER_VIEW') || userMenus.includes('CUSTOMER_COMPANY_VIEW') || userMenus.includes('CUSTOMER_BANK_VERIFICATION_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-account',
              title: 'Penyewa',
              to: '/penyewa',
              notifkey: 'menu_penyewa',
              separator: false,
            },
          )
        }

        if (userMenus.includes('PARTNER_COMPANY_VIEW') || userMenus.includes('PARTNER_BANK_VERIFICATION_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-home-account',
              title: 'Mitra',
              to: '/mitra',
              notifkey: 'menu_mitra',
              separator: false,
            },
          )
        }

        if (userMenus.includes('PARTNER_VENDOR_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-home-city',
              title: 'Vendor',
              to: '/vendor',
              notifkey: 'menu_vendor',
              separator: false,
            },
          )
        }

        if (userMenus.includes('RESELLER_TRX_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-percent-outline',
              title: 'Commission',
              to: '/commission',
              notifkey: 'menu_commission',
              separator: true,
            },
          )
        }

        if (userMenus.includes('ASSET_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-billboard',
              title: 'Aset',
              to: '/aset',
              notifkey: 'menu_aset',
              separator: false,
            },
          )
        }

        if (userMenus.includes('ASSET_VERIFICATION')) {
          this.items.push(
            {
              icon: 'mdi-check-network',
              title: 'Verifikasi Aset',
              to: '/vaset',
              notifkey: 'menu_vaset',
              separator: false,
            },
          )
        }

        if (userMenus.includes('ASSET_IMAGE_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-image-edit',
              title: 'Verifikasi Foto',
              to: '/vfoto',
              notifkey: 'menu_vfoto',
              separator: false,
            },
          )
        }

        if (userMenus.includes('PEMESANAN_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-cart-arrow-down',
              title: 'Pesanan',
              to: '/pesanan',
              notifkey: 'menu_pesanan',
              separator: false,
            },
          )
        }

        if (userMenus.includes('PARTNER_OFFLINE_ORDER_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-cart-off',
              title: 'Penjualan Offline',
              to: '/pesananoffline',
              notifkey: 'menu_pesananoffline',
              separator: false,
            },
          )
        }

        if (userMenus.includes('PRODUKSI_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-timeline-clock',
              title: 'Produksi',
              to: '/produksi',
              notifkey: 'menu_produksi',
              separator: true,
            },
          )
        }

        if (userMenus.includes('WPOINT_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-text-box',
              title: 'TraPoin',
              to: '/trapoin',
              notifkey: 'menu_trapoin',
              separator: false,
            },
          )
        }

        if (userMenus.includes('WPOINT_TOPUP_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-plus-circle',
              title: 'Top Up TraPoin',
              to: '/topuptrapoin',
              notifkey: 'menu_topuptrapoin',
              separator: false,
            },
          )
        }

        if (userMenus.includes('WALLET_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-wallet',
              title: 'Dompet',
              to: '/dompet',
              notifkey: 'menu_dompet',
              separator: false,
            },
          )
        }

        if (userMenus.includes('INVOICE_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-script-text',
              title: 'Tagihan',
              to: '/tagihan',
              notifkey: 'menu_tagihan',
              separator: false,
            },
          )
        }

        if (userMenus.includes('WITHDRAWAL_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-bank-minus',
              title: 'Penarikan',
              to: '/penarikan',
              notifkey: 'menu_penarikan',
              separator: true,
            },
          )
        }

        if (userMenus.includes('EMPLOYEE_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-account-tie',
              title: 'User',
              to: '/user',
              notifkey: 'menu_user',
              separator: false,
            },
          )
        }

        if (userMenus.includes('PARTNER_COMPANY_FEE_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-brightness-percent',
              title: 'Persentase Biaya Layanan',
              to: '/pbl',
              notifkey: 'menu_pbl',
              separator: false,
            },
          )
        }

        if (userMenus.includes('TICKET_VIEW')) {
          this.items.push(
            {
              icon: 'mdi-message-text',
              title: 'Tiket',
              to: '/tiket',
              notifkey: 'menu_tiket',
              separator: false,
            },
          )
        }

        if (userMenus.includes('MENU_GRANT_REVOKE')) {
          this.items.push(
            {
              icon: 'mdi-account-cog',
              title: 'Pengaturan Peran User',
              to: '/peranuser',
              notifkey: 'menu_peranuser',
              separator: false,
            },
          )

          this.items.push(
            {
              icon: 'mdi-account-cog',
              title: 'Pengaturan Peran Mitra',
              to: '/peranmitra',
              notifkey: 'menu_peranmitra',
              separator: false,
            },
          )
        }

        // if (userMenus.includes('EMPLOYEE_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account',
        //       title: 'Pegawai',
        //       to: '/employee',
        //       notifkey: 'menu_employee',
        //     },
        //   )
        // }

        // if (userMenus.includes('CUSTOMER_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-switch',
        //       title: 'Penyewa',
        //       to: '/customer',
        //       notifkey: 'menu_customer',
        //     },
        //   )
        // }

        // if (userMenus.includes('CUSTOMER_COMPANY_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-star',
        //       title: 'Perusahaan Penyewa',
        //       to: '/customercompany',
        //       notifkey: 'menu_customer_company',
        //     },
        //   )
        // }

        // if (userMenus.includes('PARTNER_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-group',
        //       title: 'Mitra',
        //       to: '/partner',
        //       notifkey: 'menu_partner',
        //     },
        //   )
        // }

        // if (userMenus.includes('PARTNER_COMPANY_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-tie-outline',
        //       title: 'Perusahaan Mitra',
        //       to: '/partnercompany',
        //       notifkey: 'menu_partner_company',
        //     },
        //   )
        // }

        // if (userMenus.includes('PARTNER_BANK_VERIFICATION_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-tie-outline',
        //       title: 'Verfikasi Bank',
        //       to: '/bankverification',
        //       notifkey: 'menu_verify_bank',
        //     },
        //   )
        // }

        // if (userMenus.includes('PARTNER_OFFLINE_ORDER_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-supervisor-circle-outline',
        //       title: 'Penjualan Offline Partner',
        //       to: '/partnerofflineorder',
        //       notifkey: 'menu_partner_offline_sales',
        //     },
        //   )
        // }

        // if (userMenus.includes('PARTNER_VENDOR_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-table-border',
        //       title: 'Vendor Mitra',
        //       to: '/partnervendor',
        //       notifkey: 'menu_partner_vendor',
        //     },
        //   )
        // }

        // if (userMenus.includes('PARTNER_COMPANY_FEE_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-arrow-right-outline',
        //       title: 'Bagi Hasil Mitra',
        //       to: '/partnercompanyfee',
        //       notifkey: 'menu_partner_share',
        //     },
        //   )
        // }

        // if (userMenus.includes('MENU_VENDOR')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-switch',
        //       title: 'Vendor',
        //       to: '/vendor',
        //       notifkey: 'vendor',
        //     },
        //   )
        // }

        // if (userMenus.includes('WALLET_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-wallet-outline',
        //       title: 'Dompet',
        //       to: '/wallet',
        //       notifkey: 'menu_wallet',
        //     },
        //   )
        // }

        // if (userMenus.includes('WPOINT_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-wallet-plus-outline',
        //       title: 'TraPoin',
        //       to: '/walletpoint',
        //       notifkey: 'menu_wpoint',
        //     },
        //   )
        // }

        // if (userMenus.includes('TICKET_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-ticket-confirmation-outline',
        //       title: 'Tiket',
        //       to: '/ticket',
        //       notifkey: 'menu_ticket',
        //     },
        //   )
        // }

        // if (userMenus.includes('ASSET_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-cube-outline',
        //       title: 'Aset',
        //       to: '/asset',
        //       expandable: true,
        //       identifier: 'asset',
        //       notifkey: 'menu_asset',
        //     },
        //   )
        // }

        // if (userMenus.includes('ASSET_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: '',
        //       title: 'Aset Detail',
        //       to: '/assetdetail',
        //       submenu: true,
        //       identifier: 'asset-child',
        //       hideDefault: true,
        //       notifkey: 'menu_asset',
        //     },
        //   )
        // }

        // if (userMenus.includes('ASSET_VERIFICATION')) {
        //   this.items.push(
        //     {
        //       icon: '',
        //       title: 'Verifikasi Aset',
        //       to: '/assetsverification',
        //       submenu: true,
        //       identifier: 'asset-child',
        //       hideDefault: true,
        //       notifkey: 'sub_menu_asset_verification',
        //     },
        //   )
        // }

        // if (userMenus.includes('ASSET_IMAGE_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: '',
        //       title: 'Verifikasi Foto Aset',
        //       to: '/assetphotoverification',
        //       submenu: true,
        //       identifier: 'asset-child',
        //       hideDefault: true,
        //       notifkey: 'sub_menu_asset_photo_verification',
        //     },
        //   )
        // }

        // if (userMenus.includes('INVOICE_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-receipt',
        //       title: 'Tagihan',
        //       to: '/invoice',
        //       notifkey: 'menu_invoice',
        //     },
        //   )
        // }

        // if (userMenus.includes('PEMESANAN_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-book-open-variant',
        //       title: 'Pesanan',
        //       to: '/order',
        //       notifkey: 'menu_order',
        //     },
        //   )
        // }

        // if (userMenus.includes('PRODUKSI_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-engine-outline',
        //       title: 'Produksi',
        //       to: '/production',
        //       notifkey: 'menu_production',
        //     },
        //   )
        // }

        // if (userMenus.includes('WITHDRAWAL_VIEW')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-bank-minus',
        //       title: 'Penarikan',
        //       to: '/withdrawal',
        //       notifkey: 'menu_withdrawal',
        //     },
        //   )
        // }

        // if (userMenus.includes('MENU_GRANT_REVOKE')) {
        //   this.items.push(
        //     {
        //       icon: 'mdi-account-cog-outline',
        //       title: 'Role Management - Internal',
        //       to: '/management',
        //       notifkey: 'menu_management',
        //     },
        //     {
        //       icon: 'mdi-account-cog-outline',
        //       title: 'Role Management - Partner',
        //       to: '/managementpartner',
        //       notifkey: 'menu_management_partner',
        //     },
        //   )
        // }
      },

      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },

      iconClick () {
        this.$router.push({ name: 'Dashboard' }).catch(() => {})
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>

<style scoped>
  #logoCompany {
    cursor: pointer;
  }
</style>
